import React from 'react'
import PropTypes from 'prop-types'
import { useScrollTrigger, Zoom } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

export default function ScrollToTop({ children, anchorId }) {
  const classes = useStyles()

  const trigger = useScrollTrigger({
    disableHysteresis: true, // dependence of output on the history
    threshold: 100,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      `#${anchorId}`
    )

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollToTop.propTypes = {
  /**
   * An element to render
   */
  children: PropTypes.element.isRequired,

  /**
   * ID of the element to scroll to
   */
  anchorId: PropTypes.string.isRequired,
}
