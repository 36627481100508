import { createTheme } from '@material-ui/core'

export const WHITE = '#ffffff'
export const BLACK = '#000000'

export const PRIMARY = '#131378'
export const PRIMARY_LIGHT = '#4f3ca8'
export const PRIMARY_DARK = '#00004b'

export const PRIMARY2 = '#FFDE03'

export const SECONDARY = WHITE
export const SECONDARY_LIGHT = WHITE
export const SECONDARY_DARK = WHITE

const overrides = {
  MuiButton: {
    root: {
      textTransform: 'capitalize',
      '&:focus': {
        outline: 'none',
        textDecoration: 'none',
      },
    },
  },
}

export const DefaultThemeLight = createTheme({
  palette: {
    primary: {
      light: PRIMARY_LIGHT,
      main: PRIMARY,
      dark: PRIMARY_DARK,
      contrastText: WHITE,
    },
    primary2: {
      main: PRIMARY2,
    },
    secondary: {
      light: SECONDARY_LIGHT,
      main: SECONDARY,
      dark: SECONDARY_DARK,
      contrastText: BLACK,
    },
    background: {
      default: WHITE,
    },
    text: {
      primary: BLACK,
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    ...overrides,
  },
})
