import {
  AppBar,
  Drawer,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useTheme,
  Fab,
  useMediaQuery,
} from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardArrowUp, Menu as MenuIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
import ScrollToTop from './ScrollToTop'

const BACK_TO_TOP_ANCHOR = 'back-to-top-anchor'

export const DRAWER_WIDTH = 210

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  title: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#ffffff',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  drawerContent: {
    flexGrow: 1,
  },
}))

export default function NavigationBar({
  showDrawer,
  drawerContent,
  mainContent,
  showBackToTop,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const CustomLink = (props) => <Link component={GatsbyLink} {...props} />

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={showDrawer ? `${classes.appBar}` : null}
      >
        <Toolbar variant="dense">
          {showDrawer && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            className={classes.title}
            component={CustomLink}
            variant="h6"
            to="/"
            noWrap
          >
            flvyu
          </Typography>
        </Toolbar>
      </AppBar>
      {showDrawer && (
        <Fragment>
          <nav className={classes.drawer} aria-label="site links">
            <Drawer
              variant={isMobileScreen ? 'temporary' : 'permanent'}
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                KeepMounted: isMobileScreen,
              }}
            >
              {drawerContent}
            </Drawer>
          </nav>
          <div className={classes.drawerContent}>
            <div className={classes.toolbar} id={BACK_TO_TOP_ANCHOR} />
            {mainContent}
          </div>
          {showBackToTop && (
            <ScrollToTop anchorId={BACK_TO_TOP_ANCHOR}>
              <Fab
                color="primary"
                size="medium"
                aria-label="scroll back to the top"
              >
                <KeyboardArrowUp />
              </Fab>
            </ScrollToTop>
          )}
        </Fragment>
      )}
    </div>
  )
}

NavigationBar.propTypes = {
  /**
   * Should the drawer be displayed
   */
  showDrawer: PropTypes.bool,

  /**
   * Contents of the drawer
   */
  drawerContent: PropTypes.node,

  /**
   * Content for the main section
   */
  mainContent: PropTypes.node,

  /**
   * should the back to the top fab be displayed?
   */
  showBackToTop: PropTypes.bool,
}

NavigationBar.defaultProps = {
  showDrawer: false,
  drawerContent: null,
  mainContent: null,
  showBackToTop: true,
}
