import { CssBaseline } from '@material-ui/core'
import Helmet from 'react-helmet'
import NavigationBar from '../Navigation'
import PropTypes from 'prop-types'
import React from 'react'
import SearchEngineOptimization from '../Seo'
import { ThemeProvider } from '@material-ui/core/styles'

export const BaseLayout = ({ theme, children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default function DefaultLayout({ children, navigationBar, SEO }) {
  return (
    <>
      <CssBaseline />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Raleway:wght@600&family=Source+Code+Pro:ital,wght@1,900&display=swap&family=Source+Sans+Pro:ital,wght@1,600&display=swap&family=PT+Serif"
          rel="stylesheet"
        />
      </Helmet>
      <SearchEngineOptimization {...SEO} />
      {navigationBar}
      {children}
    </>
  )
}

DefaultLayout.propTypes = {
  /**
   * Anything that can be rendered in the layout.
   */
  children: PropTypes.node,

  /**
   * A navigation bar component
   */
  navigationBar: PropTypes.node,

  /**
   * SEO information for the page
   */
  SEO: PropTypes.shape({
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
  }),
}

DefaultLayout.defaultProps = {
  children: null,
  navigationBar: <NavigationBar />,
  SEO: {
    title: 'flvyu',
  },
}
